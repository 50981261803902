<template lang="pug">
  .d-flex.flex-column.align-center.justify-center
    SuccessPopupCheck.mb-5
    .text-xl.mb-5.font-weight-medium Congratulations!
    .text-base.mb-5(v-if="accountName.length") Account {{ accountName }} was successfully created.
    v-btn(
      color="primary"
      @click="$emit('close')"
    )
      | Done
</template>

<script>
  import SuccessPopupCheck from '@/assets/images/notification/success-popup-check.svg?inline';
  export default {
    components: {
      SuccessPopupCheck
    },
    props: {
      accountName: {
        type: String,
        default: ''
      }
    }
  };
</script>
