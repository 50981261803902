







































































































import * as vr from '@/helpers/validation';
import debounce from 'lodash/debounce';
import { checkRegisterData } from '@/api/Auth';
import { ValidationRules } from '@/api/schema';
import BaseDialog from '@/components/BaseDialog.vue';
import { createAccountOperator } from '@/api/Operator';
import { formatBrandName } from '@/helpers/operatorHelpers';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { capitalize } from 'lodash';
import {
  monthlyGGRs,
  monthlyActiveUsers
} from '@/mixins/Onboarding/OnboardingMixin';

import OnboardingCreateOperatorGroup from './SuperAdminCreateOperatorGroup.vue';
import OnboardingCreateOperatorItem from './SuperAdminCreateOperatorItem.vue';
import OnboardingCreateOperatorComplete from './SuperAdminCreateOperatorComplete.vue';

const CREATE_OPERATOR_STEPS = {
  FORM: 'FORM',
  CHECK_ALL: 'CHECK_ALL',
  COMPLETE: 'COMPLETE'
};

const DEFAULT_FORM_DATA = {
  ggr: '',
  tokenSymbol: '',
  activeUsers: '',
  website: ''
};
export default {
  name: 'OnboardingCreateOperatorDialog',
  components: {
    OnboardingCreateOperatorComplete,
    OnboardingCreateOperatorItem,
    OnboardingCreateOperatorGroup,
    BaseDialog
  },
  data(): any {
    return {
      CREATE_OPERATOR_STEPS,
      currentStep: CREATE_OPERATOR_STEPS.FORM,
      monthlyGGRs,
      monthlyActiveUsers,
      loading: false,
      formValid: false,
      asyncValid: {
        website: true,
        currency: true
      },
      formData: { ...DEFAULT_FORM_DATA }
    };
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    rules(): ValidationRules {
      return {
        website: [
          (v) => vr.required(v, 'Website URL'),
          vr.urlWithoutProtocol,
          this.asyncValid.website || 'Already used'
        ],
        currency: [
          (v) => vr.required(v, 'Token symbol'),
          (v) => !v?.includes(' ') || 'No blank space available',
          (v) => vr.maxLength(v, 5),
          (v) => vr.minLength(v, 3),
          vr.validByRegExp,
          this.asyncValid.currency || 'Already used'
        ]
      };
    },
    brandName(): string {
      return capitalize(formatBrandName(this.formData.website));
    },
    currentMonthlyGGRs(): any {
      return monthlyGGRs.find((item) => item.text === this.formData.ggr);
    },

    formatSiteUrl(): string {
      if (!this.formData.website) return '';

      return 'https://' + this.formData.website;
    }
  },
  methods: {
    asyncValidateDebounced: debounce(function (type: string, value: string) {
      if (value) this.asyncValidate(type, value);
    }, 300) as (type: string, value: string) => void,

    asyncValidate(type: string, value: string): void {
      checkRegisterData(type)(value)
        .then((valid) => (this.asyncValid[type] = valid))
        .catch(() => (this.asyncValid[type] = false));
    },

    resetForm(): void {
      this.currentStep = CREATE_OPERATOR_STEPS.FORM;
      this.formData = { ...DEFAULT_FORM_DATA };
      this.$refs.form.resetValidation();
    },

    closeDialog(): void {
      this.$emit('input', false);
      setTimeout(this.resetForm, 300);
    },

    submitHandler(): void {
      if (this.loading) return;

      const { tokenNumber, tokenPrice } = this.currentMonthlyGGRs;

      const payload = {
        ...this.formData,
        operatorName: this.brandName,
        tokenName: `${this.brandName} Token`,
        businessType: 'CASINO',
        website: this.formatSiteUrl,
        tokenNumber,
        tokenPrice
      };

      this.loading = true;

      createAccountOperator(this.$route.params.id, payload)
        .then(() => {
          this.currentStep = CREATE_OPERATOR_STEPS.COMPLETE;
          this.$emit('update-accounts');
        })
        .catch(errorToastMessage)
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
